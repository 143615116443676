<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-md-10 py-5">
                <div class="d-block mb-4">
                    <div class="d-flex justify-content-between mb-4">
                        <div>
                            <div class="d-block">{{ forSet.nama_gelombang }}</div>
                            <div class="h1 mb-1">Setup {{ forSet.nama_formulir }}</div>
                            <!-- <div style="font-size: 13px;" class="badge badge-info"><span class="fe fe-info mr-2 d-inline-block"></span>Kode Setup Formulir: {{ forSet.kode_formulir }}</div> -->
                            <span class="badge badge-info m-0 mr-2"><i class="fe fe-info mr-2 d-inline-block"></i> Kode Setup Formulir: {{ forSet.kode_formulir }}</span>
                            <span class="badge badge-success m-0 mr-2">Unit : {{ forSet.unit_title }}</span>
                            <span v-if="forSet.sub_unit_title" class="badge badge-warning m-0 mr-2">Sub Unit : {{ forSet.sub_unit_title }}</span>
                            <span class="badge badge-light m-0 mr-2">keterangan : {{ forSet.keterangan }}</span>
                        </div>
                        <div>
                            <div class="d-flex">
                                <div class="border rounded p-3 mb-4 mr-4">
                                    <div class="d-flex align-items-center">
                                        <div class="
                                                custom-control custom-switch">
                                            <!-- SETUP PUBLISH FORMULIR  -->
                                            <input type="checkbox" class="custom-control-input" v-model="publishIsTrue" id="switchOne" @change="setup_publish_formulir()">
                                            <label class="custom-control-label" for="switchOne"></label>
                                        </div>
                                        <label class="font-weight-bold my-0 ml-2">{{ forSet.status }}</label>
                                    </div>
                                </div>
                                <router-link :to="{name : 'master.gelombang.detail', params : {id : gelombang_id}}" class="btn btn-white mr-3 p-3 mb-4 mr-4">Kembali</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TAB NAVIGATION -->
                <div class="d-block border-bottom mb-4">
                    <ul class="nav nav-tabs nav-overflow mt-0 border-0 mb-0" role="tablist">
                        <li class="nav-item">
                            <a href="#registrationForm" class="nav-link active pt-0 pb-2" data-toggle="tab" aria-selected="true">
                                Kelola Formulir
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#registrationInfo" class="nav-link pt-0 pb-2" data-toggle="tab" aria-selected="true">
                                Informasi Formulir
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#registrationStep" class="nav-link pt-0 pb-2" data-toggle="tab" aria-selected="true">
                                Tahap Registrasi
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#registrationRe" class="nav-link pt-0 pb-2" data-toggle="tab" aria-selected="true">
                                Informasi Lapor Diri
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- TAB CONTENT -->
                <div class="tab-content">
                    <!-- TAB REGISTRANT DATA -->
                    <div class="tab-pane fade show active" id="registrationForm" role="tabpanel">
                        <div class="d-flex pb-3 mb-4 border-bottom">
                            <a class="mr-4" @click="currentTabComponent = 'berkasPDaftar'">
                                <div class="d-block text-center mb-2">
                                    <div class="icon-circle-lg icon-circle-primary"><span class="fe fe-file-plus text-lg"></span></div>
                                </div>
                                <div class="d-block text-center text-dark">Berkas Pendaftaran</div>
                            </a>
                            <!-- <a class="block-muted block-muted-have-icon-circle mr-4">
                                        <div class="d-block text-center mb-2">
                                            <div class="icon-circle-lg icon-circle-primary"><span class="fe fe-user-plus text-lg"></span></div>
                                        </div>
                                        <div class="d-block text-center text-dark">Form Pendaftaran</div>
                                    </a> -->
                            <a class="mr-4" @click="currentTabComponent = 'berkasDUlang'">
                                <div class="d-block text-center mb-2">
                                    <div class="icon-circle-lg icon-circle-primary"><span class="fe fe-user-plus text-lg"></span></div>
                                </div>
                                <div class="d-block text-center text-dark">Berkas Lapor Diri</div>
                            </a>
                        </div>
                        <keep-alive>
                            <component :is="currentTabComponent"></component>
                        </keep-alive>
                    </div>
                    <!-- TAB REGISTRANT INFORMATION -->
                    <div class="tab-pane fade" id="registrationInfo" role="tabpanel">
                        <div class="d-block mb-4 pb-4 border-bottom">
                            <form @submit.prevent="submitForm" enctype="multipart/form-data">
                                <div class="h2 mb-4">Informasi Formulir</div>
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        1
                                    </div>
                                    <div class="form-group mb-4 flex-grow-1">
                                        <label class="font-weight-bold">Deskripsi Formulir</label>
                                        <ckeditor v-model="forSet.deskripsi_formulir" ></ckeditor>
                                    </div>
                                </div>
                                <br>
                                <div class="h2 mb-4">Informasi Test Seleksi Masuk </div>
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        1
                                    </div>
                                    <div class="form-group mb-4 flex-grow-1">
                                        <label class="font-weight-bold">Deskripsi Tes</label>
                                        <ckeditor v-model="forSet.informasi_tes" ></ckeditor>
                                    </div>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        2
                                    </div>
                                    <div class="form-group mb-4 flex-grow-1">
                                        <label class="font-weight-bold">Deskripsi Lolos Seleksi</label>
                                        <ckeditor v-model="forSet.deskripsi_lulus" ></ckeditor>
                                    </div>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        3
                                    </div>
                                    <div class="form-group mb-4 flex-grow-1">
                                        <label class="font-weight-bold">Deskripsi Tidak Lolos Seleksi</label>
                                        <ckeditor v-model="forSet.deskripsi_tidak_lulus" ></ckeditor>
                                    </div>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        4
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group mb-4">
                                                    <label class="font-weight-bold">Jadwal Tes</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <span class="fe fe-calendar"></span>
                                                            </span>
                                                        </div>
                                                        <input type="date" v-model="forSet.jadwal_tes" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        5
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group mb-4">
                                                    <label class="font-weight-bold">Tautan Tes</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <span class="fe fe-link"></span>
                                                            </span>
                                                        </div>
                                                        <input type="text" v-model="forSet.url_tes" class="form-control" placeholder="https://google.com">
                                                    </div>
                                                    <div id="alertUrl" class="d-none">
                                                        <small class="text-danger">Tautan tes yang anda masukan tidak valid.</small><small> Example : https://</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="h2 mb-4">Tipe Metode Pembayaran</div>
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        1
                                    </div>
                                    <div class="form-group mb-4 flex-grow-1">
                                        <label class="font-weight-bold">Deskripsi Pembayaran (Payment Gateway) </label>
                                        <ckeditor v-model="forSet.payment_gateway_information" ></ckeditor>
                                    </div>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        1
                                    </div>
                                    <div class="form-group mb-4 flex-grow-1">
                                        <label class="font-weight-bold">Deskripsi Pembayaran (External Gateway)</label>
                                        <ckeditor v-model="forSet.external_gateway_information" ></ckeditor>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end pt-3">
                                    <button type="submit" class="btn btn-primary">Setup & simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- TAB REGISTRANT STEP / POSITION -->
                    <div class="tab-pane fade" id="registrationStep" role="tabpanel">
                        <div class="d-block mb-4 pb-4 border-bottom">
                            <div class="d-flex w-100">
                                <div class="icon-circle flex-shrink-0 mr-4">
                                    1
                                </div>
                                <div class="form-group mb-4 flex-grow-1">
                                    <div class="h2 mb-2">Pilih Tahap Registrasi</div>
                                    <div class="d-block mb-2">
                                        Pilih tahap registrasi PPDB yang akan di gunakan
                                    </div>
                                    <div class="row card">
                                        <div class="col-md-12 card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <div v-for="(items, index ) in listStepPendaftaran" :key="index">
                                                        <div class="pretty p-svg p-curve">
                                                            <input type="checkbox" checked="checked" :value="items.id" v-model="stepcheckbox">
                                                            <div class="state p-primary">
                                                                <svg class="svg svg-icon" viewBox="0 0 20 20">
                                                                    <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                                                                </svg>
                                                                <label>{{ items.title }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end pt-0">
                                        <button @click.prevent="setup_checked_step()" class="btn btn-primary">Setup & simpan tahap registrasi</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-block mb-4 pb-4 border-bottom">
                            <div class="d-flex w-100">
                                <div class="icon-circle flex-shrink-0 mr-4">
                                    2
                                </div>
                                <div class="form-group mb-4 flex-grow-1">
                                    <div class="h2 mb-4">Urutkan Tahap Registrasi</div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="d-block">
                                                <span class="fe fe-info h1 m-0"></span>
                                                <div class="d-block mt-2">
                                                    Anda dapat mengatur urutan pendaftaran menggunakan drag & drop.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-9">

                                            <div class="kanban-category px-3 pt-3 pb-1 bg-light rounded" tag="ul">
                                                <draggable v-model="list_enable" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false">
                                                    <div class="kanban-item border-left pl-3" v-for="element in list_enable" :key="element.step_id">
                                                        <div class="card mb-3" style="cursor:move;">
                                                            <div class="card-body px-3 py-2">
                                                                <div class="d-flex align-items-center">
                                                                    <div class="flex-shrink-0 mr-3">
                                                                        <div class="d-flex flex-column align-items-center text-muted">
                                                                            <span class="fe fe-chevron-up mb-n2"></span>
                                                                            <span class="fe fe-chevron-down mt-n2"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="font-weight-bold">
                                                                        Tahap {{element.label}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </draggable>
                                                <div class="kanban-item border-left pl-3" v-for="element in list_disable" :key="element.step_id">
                                                    <div class="card bg-light mb-3">
                                                        <div class="card-body px-3 py-2">
                                                            <div class="d-flex align-items-center">
                                                                <div class="flex-shrink-0 mr-3">
                                                                    <div class="d-flex flex-column align-items-center text-muted">
                                                                        <span class="fe fe-chevron-up mb-n2"></span>
                                                                        <span class="fe fe-chevron-down mt-n2"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="font-weight-bold">
                                                                    Tahap {{element.label}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="d-flex justify-content-end pt-3">
                                                <button @click.prevent="setup_step_drog_drag()" class="btn btn-primary">Setup & Simpan tahap registrasi</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-block mb-4 pb-4 border-bottom" v-if="this.$beta_custom_form">
                            <div class="d-flex w-100">
                                <div class="icon-circle flex-shrink-0 mr-4">
                                    3
                                </div>
                                <div class="form-group mb-4 flex-grow-1">
                                    <div class="h2 mb-2">Custom Formulir Pendaftaran</div>
                                    <div class="d-block mb-2">
                                        Anda bisa melakukan custom formulir pendaftaran
                                        sesuai dengan yang anda inginkan.
                                    </div>
                                </div>
                                <div class="justify-content-end pt-0">
                                    <router-link :to="{name : 'custom_formulir', params : {id : this.$route.params.id}}" class="btn btn-primary">Custom Formulir</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- TAB RE-REGISTRATION -->
                    <div class="tab-pane fade" id="registrationRe" role="tabpanel">
                        <div class="d-block mb-4 pb-4 border-bottom">
                            <form @submit.prevent="submitForm" enctype="multipart/form-data">
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        1
                                    </div>
                                    <div class="form-group mb-4 flex-grow-1">
                                        <label class="font-weight-bold">Informasi Lapor Diri</label>
                                        <ckeditor v-model="forSet.informasi_reg_ulang" ></ckeditor>
                                    </div>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        2
                                    </div>
                                    <div class="form-group mb-4 flex-grow-1">
                                        <label class="font-weight-bold">Lolos Lapor Diri</label>
                                        <ckeditor v-model="forSet.lolos_reg_ulang" ></ckeditor>
                                    </div>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="icon-circle flex-shrink-0 mr-4">
                                        3
                                    </div>
                                    <div class="form-group mb-4 flex-grow-1">
                                        <label class="font-weight-bold">Tidak Lolos Lapor Diri</label>
                                        <ckeditor v-model="forSet.tidak_lolos_reg_ulang" ></ckeditor>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end pt-3">
                                    <button type="submit" class="btn btn-primary">Setup & simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import berkasPDaftar from "@/components/admin/Manage_berkas_pendaftaran.vue"
    import berkasDUlang from "@/components/admin/Manage_berkas_registasi_ulang.vue"
    import draggable from "vuedraggable";

    export default {
        components: {
            berkasPDaftar,
            berkasDUlang,
            draggable
        },
        data() {
            return {
                currentTabComponent: 'berkasPDaftar',
                editorConfig: {
                    toolbar: [
                        ['Source'],
                        ['Styles', 'Format', 'Font', 'FontSize'],
                        ['Bold', 'Italic'],
                        ['Undo', 'Redo'],
                        ['Link', 'Unlink', 'Anchor'],
                        ['NumberedList', 'BulletedList', 'Outdent', 'Indent'],
                        ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                    ],
                },
                stepcheckbox: [],
                listStepPendaftaran: [],
                list: [],
                list_disable: [],
                list_enable: [],
                baseUrl: this.$apiconfig,
                forSet: {
                    id: '',
                    jadwal_tes: '',
                    url_tes: '',
                    informasi_tes: '',
                    payment_gateway_information: '',
                    external_gateway_information: '',
                    informasi_reg_ulang: '',
                    lolos_reg_ulang: '',
                    tidak_lolos_reg_ulang: ''
                },
                disableDraggable: false,
                editable: true,
                isDragging: false,
                delayedDragging: false,
                publishIsTrue: '',
                gelombang_id : ''
            }
        },
        created() {
            this.getData();
            this.generateCustomForm();
        },
        methods: {
            // MODAL
            show_modal() {
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            // GET DATA
            getData() {
                this.fetching = true;
                // Get step pendaftaran
                this.$http.get(this.baseUrl + `admin/setupformulir/list/steppendaftaran`)
                    .then(response => {
                        this.listStepPendaftaran = response.data.data;
                    });
                // Get data detail Registertrasi (After submit formulir)
                this.$http.get(this.baseUrl + `admin/gelombang/detail/formulir?id=${this.$route.params.id}`)
                    .then(response => {
                        this.gelombang_id = response.data.data[0].gelombang_id;
                        this.forSet.nama_gelombang = response.data.data[0].nama_gelombang;
                        this.forSet.nama_periode = response.data.data[0].nama_periode;
                        this.forSet.nama_formulir = response.data.data[0].nama_formulir;
                        this.forSet.kode_formulir = response.data.data[0].kode_formulir;
                        this.forSet.jadwal_tes = response.data.data[0].jadwal_tes;
                        this.forSet.url_tes = response.data.data[0].url_tes;
                        this.forSet.payment_gateway_information = response.data.data[0].payment_gateway_information;
                        this.forSet.external_gateway_information = response.data.data[0].external_gateway_information;
                        this.forSet.informasi_tes = response.data.data[0].informasi_tes;
                        this.forSet.informasi_reg_ulang = response.data.data[0].informasi_reg_ulang;
                        this.forSet.lolos_reg_ulang = response.data.data[0].lolos_reg_ulang;
                        this.forSet.tidak_lolos_reg_ulang = response.data.data[0].tidak_lolos_reg_ulang;
                        this.forSet.deskripsi_formulir = response.data.data[0].deskripsi_formulir;
                        this.forSet.deskripsi_lulus = response.data.data[0].deskripsi_lulus;
                        this.forSet.deskripsi_tidak_lulus = response.data.data[0].deskripsi_tidak_lulus;
                        this.forSet.status = response.data.data[0].status;
                        this.forSet.unit_title = response.data.data[0].unit_title;
                        this.forSet.sub_unit_title = response.data.data[0].sub_unit_title;
                        this.forSet.keterangan = response.data.data[0].keterangan;
                        this.list = response.data.data[0].list;
                        this.list_disable = response.data.data[0].list_disable;
                        this.list_enable = response.data.data[0].list_enable;
                        this.stepcheckbox = JSON.parse(response.data.data[0].step_register);
                        this.fetching = false;

                        if (this.forSet.status == 'unpublish') {
                            this.publishIsTrue = false
                        } else {
                            this.publishIsTrue = true
                        }
                    })
                    .catch(() => {
                        this.fetching = false;
                    });
            },
            // Set form
            submitForm() {
                var re = /^https?:\/\//;
                if (!re.test(this.forSet.url_tes) && this.forSet.url_tes) {
                    const alertUrl = document.getElementById("alertUrl");
                    alertUrl.classList.remove("d-none");
                    this.$swal({
                        icon: 'error',
                        title: "Peringatan!",
                        text: "Tautan tes yang anda masukan tidak valid",
                        showConfirmButton: true,
                        timer: 5000
                    })
                } else {
                    var fd = new FormData()
                    fd.append('setup', 'form')
                    fd.append('formulir_id', this.$route.params.id)
                    fd.append('jadwal_tes', this.forSet.jadwal_tes)
                    fd.append('url_tes', this.forSet.url_tes)
                    fd.append('payment_gateway_information', this.forSet.payment_gateway_information)
                    fd.append('external_gateway_information', this.forSet.external_gateway_information)
                    fd.append('informasi_reg_ulang', this.forSet.informasi_reg_ulang)
                    fd.append('lolos_reg_ulang', this.forSet.lolos_reg_ulang)
                    fd.append('tidak_lolos_reg_ulang', this.forSet.tidak_lolos_reg_ulang)
                    fd.append('informasi_tes', this.forSet.informasi_tes)
                    fd.append('deskripsi_formulir', this.forSet.deskripsi_formulir)
                    fd.append('deskripsi_lulus', this.forSet.deskripsi_lulus)
                    fd.append('deskripsi_tidak_lulus', this.forSet.deskripsi_tidak_lulus)
                    this.$http({
                        url: this.baseUrl + 'admin/gelombang/setup/formulir',
                        method: 'post',
                        data: fd
                    }).then((response) => {
                        if (response.data.status == false) {
                            this.$swal({
                                icon: 'error',
                                title: "Formulir tidak dapat di ubah.",
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 5000
                            })
                        } else {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: 'Formulir berhasil di update',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            const alertUrl = document.getElementById("alertUrl");
                            alertUrl.classList.add("d-none");
                        }

                        this.getData();
                    }).catch(error => {
                        console.log(error.response.data)
                    })
                }


            },
            // SETUP PUBLISH FORMULIR 
            setup_publish_formulir() {
                if (this.publishIsTrue == true) {
                    this.forSet.status = 'publish'
                } else {
                    this.forSet.status = 'unpublish'
                }
                this.stepPendaftaran = JSON.stringify(this.list.map(x => x['step_id']))
                var fd = new FormData()
                fd.append('formulir_id', this.$route.params.id)
                fd.append('status', this.forSet.status)
                fd.append('setup', 'publish')
                this.$http({
                    url: this.baseUrl + 'admin/gelombang/setup/formulir',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if (response.data.status == false) {
                        this.$swal({
                            icon: 'error',
                            title: "Formulir tidak dapat di publish.",
                            html: response.data.message,
                            showConfirmButton: true,
                            timer: 5000
                        }).then(() => {
                            this.forSet.status = 'unpublish'
                        });
                    } else {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Formulir berhasil di ' + this.forSet.status,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    this.getData();
                }).catch(error => {
                    console.log(error.response.data)
                })
            },
            // PILIH STEP YANG AKAN DIGUNAKAN
            setup_checked_step() {
                if (this.stepcheckbox.length == 0) {
                    this.$swal({
                        icon: 'error',
                        title: "opss",
                        text: 'Pilihan tidak boleh kosong',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    var fd = new FormData()
                    fd.append('formulir_id', this.$route.params.id)
                    fd.append('step_register', JSON.stringify(this.stepcheckbox))
                    fd.append('setup', 'checked_step')
                    this.$http({
                        url: this.baseUrl + 'admin/gelombang/setup/formulir',
                        method: 'post',
                        data: fd
                    }).then((response) => {
                        if (response.data.status == false) {
                            this.$swal({
                                icon: 'error',
                                title: "Formulir tidak dapat di setup.",
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 5000
                            })
                        } else {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: 'Pilihan telah di simpan',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                        this.getData();
                    }).catch(error => {
                        console.log(error.response.data)
                    })
                }
            },
            // STEP DROG & DRAG
            setup_step_drog_drag() {
                var fd = new FormData()
                var stepEnable = this.list_enable.map(x => x['step_id'])
                var stepDisable = this.list_disable.map(x => x['step_id'])
                this.stepPendaftaran = JSON.stringify(stepEnable.concat(stepDisable));
                fd.append('setup', 'stepdrogdrag')
                fd.append('step_register', this.stepPendaftaran)
                fd.append('formulir_id', this.$route.params.id)
                this.$http({
                    url: this.baseUrl + 'admin/gelombang/setup/formulir',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if (response.data.status == false) {
                        this.$swal({
                            icon: 'error',
                            title: "Formulir tidak dapat di ubah.",
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 5000
                        })
                    } else {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Setup urutkan step pendaftaran',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    this.getData();
                }).catch(error => {
                    console.log(error.response.data)
                })
            },


            orderList() {
                this.list = this.list.sort((one, two) => {
                    return one.order - two.order;
                });
            },
            onMove({
                relatedContext,
                draggedContext
            }) {
                const relatedElement = relatedContext.element;
                const draggedElement = draggedContext.element;
                return (
                    (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
                );
            },
            // listString() {
            //     this.stepPendaftaran = JSON.stringify(this.list.map(x => x['step_id']));
            // }
            generateCustomForm: function() {
                if(this.$beta_custom_form) {
                    this.$http.post(this.$apiconfig + 'admin/setupformulir/generateCustomForm', {
                            formulir_id : this.$route.params.id
                        })
                    .then(response => {
                        if (response.data.status) {
                            console.log("Data berhasil di generate");
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            }
        },
        metaInfo: {
            title: 'Sch oolPro',
            titleTemplate: '%s - Setup Formulir'
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: !this.editable,
                    // disabled: true,
                    ghostClass: "ghost"
                };
            },
        },
        watch: {
            isDragging(newValue) {
                if (newValue) {
                    this.delayedDragging = true;
                    return;
                }
                this.$nextTick(() => {
                    this.delayedDragging = false;
                });
            }
        }

    }
</script>

<style>
    .flip-list-move {
        transition: transform 0.5s;
    }

    .no-move {
        transition: transform 0s;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .list-group {
        min-height: 20px;
    }

    .list-group-item {
        cursor: move;
    }

    .list-group-item i {
        cursor: pointer;
    }

    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>