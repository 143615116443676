<template>
    <div class="d-block">
        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <h3 class="card-header-title" id="exampleModalCenterTitle">
                            Add New Form Input
                        </h3>
                        <button type="button" class="close" @click.prevent="hide_modal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="submitSetupBerkas" enctype="multipart/form-data">
                        <ul class="list-group list-group-flush list">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div style="width: 30px;" class="flex-shrink-0">1.</div>
                                    <div class="form-group mb-4 w-100">
                                        <label class="font-weight-bold">Input Title (Label)<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <span class="fe fe-edit"></span>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" v-model="forSetBerkas.label_name" placeholder="Place your input title or label here">
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div style="width: 30px;" class="flex-shrink-0">2.</div>
                                    <div class="form-group mb-4 w-100">
                                        <label class="font-weight-bold">Note<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <span class="fe fe-edit"></span>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" v-model="forSetBerkas.note" placeholder="Place your note here">
                                        </div>
                                    </div>
                                </div>
                                <!-- Input Requirement -->
                                <div class="d-flex">
                                    <div style="width: 30px;" class="flex-shrink-0">3.</div>
                                    <div class="d-block border rounded p-3 w-100 mb-4">
                                        <div class="form-group m-0 w-100">
                                            <div class="d-flex align-items-center">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" v-model="forSetBerkas.required" id="switchketberkas">
                                                    <label class="custom-control-label" for="switchketberkas"></label>
                                                </div>
                                                <label class="font-weight-bold my-0 ml-2">Input Required</label>
                                            </div>
                                        </div>
                                        <div class="d-block px-3 py-2 bg-warning rounded mt-3 text-sm">
                                            If this checked, your input data file be required to submit
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ul>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click.prevent="hide_modal()">Batal & Tutup</button>
                                <button type="submit" class="btn btn-primary">Simpan & Buat Formulir</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================ =  = -->
        <div class="d-flex align-items-center justify-content-between mb-4 pt-3">
            <div class="d-block h4 m-0 font-weight-normal">
                Custom Berkas Pendaftaran
            </div>
            <div class="d-flex align-items-center">
                <div class="text-muted h4 m-0 font-weight-normal mr-3">{{ dataUploadBerkas.length }} form added</div>
                <div>
                    <button type="button" @click="show_modal()" class="btn btn-primary btn-sm"><span class="fe fe-save mr-2"></span>Add New</button>
                </div>
            </div>
        </div>
        <div class="d-block bg-light px-3 pt-3 pb-2 rounded">
            <div v-if="dataUploadBerkas.length == 0" class="d-block bg-white shadow-sm border rounded px-3 py-5 text-muted text-center mb-2">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <span class="fe fe-zap h3"></span><br>
                        Custom input is empty, add new input by clicking 'Add New' button on top right.
                    </div>
                </div>
            </div>
            <div v-else class="d-block">
                <div class="list-pembelajaran mb-3" v-for="(dtBerkas, index ) in dataUploadBerkas" :key="index">
                    <div class="list-position">
                        <button class="btn btn-white btn-sm btn-disabled mb-1" disabled><span class="fe fe-chevron-up"></span></button>
                        <button class="btn btn-white btn-sm"><span class="fe fe-chevron-down"></span></button>
                    </div>
                    <div class="card card-sm mb-0 border lift">
                        <div class="card-body p-3">
                            <div class="row pb-3 mb-3 border-bottom">
                                <div class="col-8">
                                    <div class="d-block mb-1">
                                        <div class="small text-muted">Title (Label)</div>
                                        <div class="d-block font-weight-bold">{{ dtBerkas.label_name }}</div>
                                    </div>
                                </div>
                                <div class="col-4 text-right">
                                    <button @click.prevent="setupBerkasEdit(dtBerkas.id)" class="btn btn-sm btn-link p-0 text-dark mr-2"><span class="fe fe-trash mr-2"></span>Edit</button>
                                    <button @click.prevent="setupBerkasDelete(dtBerkas.id)" class="btn btn-sm btn-link p-0 text-danger"><span class="fe fe-trash mr-2"></span>Delete</button>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 mr-3">
                                            <div class="icon-circle">
                                                <span class="fe fe-grid text-muted"></span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="small text-muted">Type</div>
                                            <div class="d-block font-weight-bold">Text File</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 mr-3">
                                            <div class="icon-circle">
                                                <span class="fe fe-alert-triangle text-muted"></span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="small text-muted">Required</div>
                                            <div class="d-block font-weight-bold">
                                                <span v-if="dtBerkas.required == 1">Yes</span>
                                                <span v-else>No</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 mr-3">
                                        <div class="icon-circle">
                                            <span class="fe fe-file text-muted"></span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="small text-muted">Max Upload Size</div>
                                        <div class="d-block font-weight-bold">
                                            10 MB
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <span v-if="dtBerkas.note">
                                <div class="d-block px-3 py-2 bg-warning rounded mt-3 text-sm">
                                    Notes: {{ dtBerkas.note }}
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                forSetBerkas: {
                    id: '',
                    setup_formulir_id: '',
                    label_name: '',
                    required: false,
                    note: '',
                    category: ''
                },
                dataUploadBerkas: [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            show_modal() {
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            getData() {
                this.fetching = true;
                // Get setup upload berkas
                this.$http.get(this.baseUrl + `admin/gelombang/form-berkas/list/pendaftaran?id=${this.$route.params.id}`)
                    .then((response) => {
                        //assign state formulir with response data
                        this.dataUploadBerkas = response.data.data;
                        this.fetching = false;
                        if (response.data.status == false) {
                            this.dataUploadBerkas = [];
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // ====================== SETUP BERKAS =================
            // save setup form berkas
            submitSetupBerkas() {
                this.forSetBerkas.category = 'pendaftaran'
                this.forSetBerkas.setup_formulir_id = this.$route.params.id
                if (this.forSetBerkas.label_name == '') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Nama label tidak boleh kosong',
                    })
                } else {
                    this.$http.post(this.baseUrl + 'admin/gelombang/form-berkas/setup', this.forSetBerkas)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: 'Custom form berkas berhasil disimpan',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.$modal.hide('my-modal')
                            this.getData()
                            this.forSetBerkas.label_name = '',
                                this.forSetBerkas.required = '',
                                this.forSetBerkas.note = ''
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        });
                }
            },
            // Edit data form berkas
            setupBerkasEdit(id) {
                this.$http.get(this.baseUrl + `admin/gelombang/form-berkas/detail?id=${id}`)
                    .then((response) => {
                        //assign state periode with response data
                        this.forSetBerkas.id = response.data.data[0].id;
                        this.forSetBerkas.label_name = response.data.data[0].label_name;
                        this.forSetBerkas.required = response.data.data[0].required;
                        this.forSetBerkas.note = response.data.data[0].note;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            // Delete data form berkas
            setupBerkasDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/gelombang/form-berkas/delete',
                            method: 'post',
                            data: fd
                        }).then(() => {
                            this.$swal(
                                'Deleted!',
                                'Data deleted successfully.',
                                'success'
                            )
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        }
    }
</script>